import React, { useContext } from 'react';
import Modal from 'react-awesome-modal';

import { GlobalContext } from '../context/GlobalContext';
import { restaurantLocations } from '../helpers';

const OrderModal = () => {
  const context = useContext(GlobalContext);


  return (
    <Modal
      visible={context.orderModalOpen}
      width="340"
      effect="fadeInUp"
      onClickAway={() => context.closeOrderModal()}>
        <div className="order-modal-content z-depth-3 Nvl-txt-center">
          <h4>Select restaurant location</h4>
          {restaurantLocations.filter(l => {
            return l.cuid && l.ruid;
          }).map((r,i) =>
            <div key={i} className="order-modal-item" data-glf-cuid={r.cuid} data-glf-ruid={r.ruid}>{r.name}</div>
          )}
        </div>
    </Modal>
  )
}

export default OrderModal
