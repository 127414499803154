import React, { useEffect, useState, useContext } from 'react';
import { Waypoint } from 'react-waypoint';
import { GlobalContext } from '../context/GlobalContext';

import { getWindowDimensions } from '../helpers';

const PageHero = props => {

  const context = useContext(GlobalContext);

  // const vid = ReactDom.findDOMNode(vidRef.current);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [iframeWidth, setIframeWidth] = useState(1920);
  const [iframeHeight, setIframeHeight] = useState(1080);

  const subTitleRef = React.createRef();
  const vidRef = React.createRef();

  const subTitleRefEnter = (event) => {
    subTitleRef.current.classList.add('el-in-view');
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
      let h = windowDimensions.height * 1.3;
      let w = h * 1.77;
      setIframeWidth(w);
      setIframeHeight(h);
    }

    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handleResize);
    }
  });

  return (
    <section className="page-hero Nvl-hero-wrap" id="Home">
      <div className="Nvl-video-fsbg">
          <video
            muted="muted"
            ref={vidRef}
            style={{objectFit: 'cover'}}
            width={`${iframeWidth}px`}
            height={`${iframeHeight}px`}
            autoPlay="autoplay"
            loop="loop"
            playsInline="playsinline"
            poster="/assets/images/vidcover.jpg">
            <source src="/assets/media/akakiko-hero.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
      </div>

      <div className="Nvl-hero-content">
          <Waypoint
            scrollableAncestor={window}
            onEnter={subTitleRefEnter}>
              <div ref={subTitleRef} className="Nvl-hero-title">
                <h1>
                  <span>EASY &bull; JAPANESE &bull; FUSION</span>
                </h1>
                {/* <button onClick={() => context.openOrderModal()} className="hero-cta">
                  <img src="/assets/images/chopsticks.svg" />
                  <span>ORDER ONLINE</span>
                </button> */}
              </div>

          </Waypoint>
      </div>
    </section>
  )
}

export default PageHero;
