import React, { useContext } from 'react';

import { GlobalContext } from '../context/GlobalContext';

const Footer = props => {

  const context = useContext(GlobalContext);

  return (
    <footer id="Nvl-footer">
      <div className="footer-grid" id="Contact">
        <div className="sm-txt-c">
          <img width="220" src="/assets/images/logo-w.svg" alt="Akakiko Cyprus logo"/>
          <p>
            <i className="akk-icon-phone"></i> <span style={{color: '#e6e6e6'}}>+357 77 778022</span>
          </p>
          <p>
            <i className="akk-icon-mail"></i> <span style={{color: '#e6e6e6'}}>info@akakiko.com.cy</span>
          </p>
        </div>

        <div>
          <ul className="footer-contact-links">
            {context.contacts.map((c, i) =>
              <li key={i} className="Nvl-txt-center">
              <a
                  href={c.link}
                  target={c.name === 'phone' || c.name === 'mail' ? '_self' : '_blank'}
                  rel={c.name === 'phone' || c.name === 'mail' ? 'nofollow' : 'noopener noreferrer'}>
                  <i className={c.icon}></i>
                </a>
              </li>
            )}
          </ul>
        </div>

        <div>
          <ul className="sponsor-list">
          <li>
            <a href="http://www.chrikar.com/" target="_blank" rel="noopener noreferrer">
              <img src="/assets/images/chrikar.png" height="90" alt="chrikar"/>
            </a>
          </li>
          <li>
            <a href="http://www.chrikar.com/" target="_blank" rel="noopener noreferrer">
              <img src="/assets/images/kikkoman.png" height="90" alt="kikkoman"/>
            </a>
          </li>
          <li>
            <a href="http://www.chrikar.com/" target="_blank" rel="noopener noreferrer">
              <img src="/assets/images/kyperounta.png" height="90" alt="kyperounta"/>
            </a>
          </li>
          <li>
            <a href="http://www.chrikar.com/" target="_blank" rel="noopener noreferrer">
              <img src="/assets/images/boutari.png" height="90" alt="boutari"/>
            </a>
          </li>
        </ul>
        </div>
      </div>

      <div className="gdpr-links">
        <a href="https://www.iubenda.com/privacy-policy/26991575/full-legal" target="_blank" rel="noopener noreferrer">PRIVACY POLICY</a>
        <span> | </span>
        <a href="https://www.iubenda.com/privacy-policy/26991575/cookie-policy" target="_blank" rel="noopener noreferrer">COOKIES</a>
      </div>

      <div className="Nvl-copyright">
        <div>
          Akakiko Cyprus &copy; 2019
        </div>
        <span> | </span>
        <div>
          Powered by <a href="https://noveldigital.pro" target="_blank" rel="noopener noreferrer"><img src="/assets/images/novel.png" alt="Novel Digital Agency Cyprus" /></a>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
