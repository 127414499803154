import React, { Component } from "react";
import { PhotoSwipe } from "react-photoswipe";
import "react-photoswipe/lib/photoswipe.css";
import fjGallery from "flickr-justified-gallery";

export default class PhotoGallery extends Component {
  photosRef = React.createRef();

  state = {
    photos: [
      {
        th: "/assets/images/akakiko-nicosia-2-th.jpg",
        src: "/assets/images/akakiko-nicosia-2.jpg",
        w: 900,
        h: 800,
      },
      {
        th: "/assets/images/akakiko-limassol-gallery-1-th.jpg",
        src: "/assets/images/akakiko-limassol-gallery-1.jpg",
        w: 900,
        h: 800,
      },
      {
        th: "/assets/images/akakiko-limassol-gallery-2-th.jpg",
        src: "/assets/images/akakiko-limassol-gallery-2.jpg",
        w: 900,
        h: 800,
      },
      {
        th: "/assets/images/g1-th.jpg",
        src: "/assets/images/g1.jpg",
        w: 800,
        h: 926,
      },
      {
        th: "/assets/images/g2-th.jpg",
        src: "/assets/images/g2.jpg",
        w: 800,
        h: 1051,
      },
      {
        th: "/assets/images/g3-th.jpg",
        src: "/assets/images/g3.jpg",
        w: 1200,
        h: 800,
      },
      {
        th: "/assets/images/g4-th.jpg",
        src: "/assets/images/g4.jpg",
        w: 1200,
        h: 800,
      },
      {
        th: "/assets/images/akakiko-limassol-gallery-3-th.jpg",
        src: "/assets/images/akakiko-limassol-gallery-3.jpg",
        w: 1920,
        h: 1389,
      },
      {
        th: "/assets/images/g6-th.jpg",
        src: "/assets/images/g6.jpg",
        w: 1200,
        h: 800,
      },
    ],
    galleryOpened: false,
    photoIndex: 0,
  };

  closeGallery = () => {
    this.setState({
      galleryOpened: false,
    });
  };

  openGallery = (idx) => {
    this.setState({
      photoIndex: idx,
    });

    this.setState({
      galleryOpened: true,
    });
  };

  componentDidMount() {
    fjGallery(window.document.getElementById("photo-gallery"), {
      itemSelector: ".fj-gallery-item",
    });
  }

  psOpts = () => {
    return {
      index: this.state.photoIndex,
    };
  };

  render() {
    return (
      <section id="Gallery" style={{ padding: "6rem" }}>
        <div id="photo-gallery" ref={this.photosRef}>
          {this.state.photos.map((p, i) => (
            <div className="fj-gallery-item" key={i}>
              <div className="fj-gallery-item-x">
                <img className="Nvl-img-flex" src={p.th} alt="test" />
                <button
                  className="Nvl-btn Nvl-btn-outline Nvl-btn-white z-depth-4"
                  onClick={() => this.openGallery(i)}
                >
                  <i className="akk-icon-picture"></i>
                  view photos
                </button>
              </div>
            </div>
          ))}
        </div>
        <PhotoSwipe
          isOpen={this.state.galleryOpened}
          items={this.state.photos}
          options={{ index: this.state.photoIndex }}
        />
        {/* <ReactBnbGallery
          show={this.state.galleryOpened}
          photos={this.state.photos}
          onClose={this.closeGallery}
          activePhotoIndex={this.state.photoIndex}/> */}
      </section>
    );
  }
}
