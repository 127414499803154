//
export const fixDecimals = (value, decimals = 2) => {
  return Math.round((value) * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

//
export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

//
export const elInView = (el) => {
  let rect = el.getBoundingClientRect();
  let elemTop = rect.top;
  let elemBottom = rect.bottom;

  // Only completely visible elements return true:
  let isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
  // Partially visible elements return true:
  //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  return isVisible;
}

//
export const arrChunk = (arr, size = 2) => {
  return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );
}

//
export const restaurantLocations = [
  {
    id: 1,
    location: 'Hilton Park Nicosia, Achaion 1, Egkomi 2413',
    tel: 77778022,
    orderLink: 'orderlink',
    name: 'AKAKIKO NICOSIA',
    img: '/assets/images/akakiko-nicosia-1.jpg',
    img_flip: '/assets/images/akakiko-nicosia-2.jpg',
    cuid: '7844da4b-b5d5-437f-8921-b2761dbb72c1',
    ruid: '78e324d7-d71e-4c6d-9693-4334b98d19ae',
    lat: 35.1638057,
    lng: 33.3241725,
    map: 'https://goo.gl/maps/wdV9bs1ZQgkozks78',
    hours: '12:00 - 23:00',
    footerCta: 'Make a reservation:'
  },
  {
    id: 4,
    location: '2 Madrid Street 2306 Nicosia',
    tel: 77778022,
    orderLink: 'orderlink',
    name: 'AKAKIKO 2GO AT NICOSIA MALL',
    img: '/assets/images/akakiko-mall-1.jpg',
    img_flip: '/assets/images/akakiko-mall-1.jpg',
    cuid: null,
    ruid: null,
    lat: 35.1492534,
    lng: 33.2852572,
    map: 'https://goo.gl/maps/EZFDLD9dkd8L6Rvw6',
    hours: '12:00 - 23:00',
    footerCta: 'For more information:'
  },
  {
    id: 2,
    location: 'The Royal Apollonia, CY 4048, Georgiou \'A 68, Limassol',
    tel: 77778022,
    orderLink: 'orderlink',
    name: 'AKAKIKO LIMASSOL',
    img: '/assets/images/akakiko-limassol-1.jpg',
    img_flip: '/assets/images/akakiko-limassol-2.jpg',
    // cuid: '7844da4b-b5d5-437f-8921-b2761dbb72c1',
    // ruid: 'fd79b427-11ca-4843-bfaa-dd8ae1cbd9c6',
    lat: 34.6972548,
    lng: 33.0928437,
    map: 'https://goo.gl/maps/FcwjxuCK1wPS3Afs8',
    hours: '12:00 - 23:00',
    footerCta: 'Make a reservation:'
  },
  // {
  //   id: 3,
  //   location: '18 Nissi Avenue Ayia Napa, Famagusta',
  //   tel: 77778022,
  //   orderLink: 'orderlink',
  //   name: 'AKAKIKO 2GO AYIA NAPA',
  //   img: '/assets/images/akakiko-napa-1.jpg',
  //   img_flip: '/assets/images/akakiko-napa-2.jpg',
  //   cuid: '7844da4b-b5d5-437f-8921-b2761dbb72c1',
  //   ruid: '91871727-ab79-42c8-be11-679a293bdc18',
  //   lat: 34.9876049,
  //   lng: 33.9479045,
  //   map: 'https://goo.gl/maps/c3rdX2sFCfezVJDn9',
  //   hours: '12:00 - 23:00',
  //   footerCta: 'Make a reservation:'
  // },
  // {
  //   id: 5,
  //   location: 'The Ivi Mare, 12 Theas Afroditis Avenue, Paphos, Cyprus 8204',
  //   tel: 26913955,
  //   orderLink: 'orderlink',
  //   name: 'AKAKIKO PAPHOS',
  //   img: '/assets/images/akakiko-ivi-mare-1.jpg',
  //   img_flip: '/assets/images/akakiko-ivi-mare-2.jpg',
  //   cuid: null,
  //   ruid: null,
  //   lat: 34.7478319,
  //   lng: 32.4235315,
  //   map: 'https://goo.gl/maps/g6yn9mLurmZDoJYq8',
  //   hours: '19:00 - 23:00',
  //   footerCta: 'Make a reservation:'
  // },
  {
    id: 6,
    location: 'Polis 1907, Griva Digeni 13 Polis Chrysochous 8855, Paphos',
    tel: 26913801,
    orderLink: 'orderlink',
    name: 'AKAKIKO POLIS',
    img: '/assets/images/akakiko-polis-1.jpg',
    img_flip: '/assets/images/akakiko-polis-2.jpg',
    cuid: null,
    ruid: null,
    lat: 35.0347529,
    lng: 32.4218773,
    map: 'https://goo.gl/maps/RHYhdWxZGLz3cmCN6',
    hours: '12:00 - 23:00',
    footerCta: 'Make a reservation:'
  },
];

export const menus = [
  {
    label: 'Akakiko Nicosia Dine In',
    pdf: 'AkakikoFoodMenuNicosia2024.pdf',
  },
  {
    label: 'Akakiko Limassol Dine In',
    pdf: 'AkakikoFoodMenuLimassol2024.pdf',
  },
  // {
  //   label: 'Akakiko Nicosia Bento Boxes Menu',
  //   pdf: 'bento_boxes_menu_print.pdf',
  // },
  // {
  //   label: 'Akakiko Nicosia Cocktails Menu',
  //   pdf: '2020_09_27/AkakikoNicosiaCocktailsMenu2020.pdf',
  // },
  // {
  //   label: 'Akakiko Nicosia Drinks Menu',
  //   pdf: '2020_09_27/AkakikoNicosiaDrinksMenu2020.pdf',
  // },
  // {
  //   label: 'Akakiko Nicosia Poke Salads Menu',
  //   pdf: '2020_09_27/AkakikoNicosiaPokeSaladsMenu2020.pdf',
  // },
  // {
  //   label: 'Akakiko Nicosia Desserts Menu',
  //   pdf: '2020_09_27/AkakikoDessertsMenu2020.pdf',
  // },
  // {
  //   label: 'Akakiko Limassol Bento Boxes Menu',
  //   pdf: 'bento-boxes-menu.pdf',
  // },
  {
    label: 'Akakiko 2GO Nicosia Mall',
    pdf: 'Akakiko2GO_TakeAwayMenu2021.pdf',
  },
  // {
  //   label: 'Akakiko Paphos & Akakiko Polis',
  //   pdf: 'AkakikoPolisMenu2021.pdf',
  // },
  // {
  //   label: 'Akakiko Paphos Dine In',
  //   pdf: 'Akakiko-Paphos-Dine-In-Menu-2022.pdf',
  // },
  {
    label: 'Akakiko Polis Dine In',
    pdf: 'AkakikoFoodMenuPolis2023.pdf',
  }
];

export const colors = {
  red: '#d00929',
  black: '#222',
  lightGrey: '#eeefea'
}

export const loadScript = (url) => {
  let index = window.document.getElementsByTagName('script')[0];
  let script = window.document.createElement('script');
  script.src = url;
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);
  index.parentNode.insertBefore(script, index);
}
