import React from 'react';
import { Waypoint } from 'react-waypoint';

const WelcomeSection = () => {

  let imgRef = React.createRef();

  const onImgEnter = (event) => {
    imgRef.current.classList.add('el-in-view');
  }

  return (
    <section className="Nvl-section Nvl-section-welcome" id="Welcome">
      <div className="Nvl-container">
        <div className="Nvl-row Nvl-row-jcsa">
          <div className="Nvl-col Nvl-welcome-content">
            <h3 className="Nvl-section-title sm-txt-c">WELCOME TO <span>AKAKIKO</span> CYPRUS</h3>
            <p className="sm-txt-c">
              The philosophy behind Akakiko is simple
              – Easy Japanese Fusion with high quality healthy food.
              Our menu offers a variety of fresh Sushi, Sashimi and Maki,
              starters such as Popcorn Shrimp, Dim Sum and Thai Calamari
              and delicious hot dishes such as Duck Sweet & Sour and Beef fillet Teppan.
            </p>
          </div>

          <div className="Nvl-col">
            <Waypoint
              scrollableAncestor={window}
              onEnter={onImgEnter}>
              <img ref={imgRef} className="Nvl-img-flex" src="/assets/images/bg2.jpg" alt="sushi"/>
            </Waypoint>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WelcomeSection;
