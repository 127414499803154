import React from 'react';
// import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { GlobalContext, GlobalProvider } from './context/GlobalContext';

import Masthead from './components/Masthead';
import DefaultLayout from './views/layout/DefaultLayout';
import Footer from './components/Footer';
// import SuspenseFallback from './components/SuspenseFallback';

import PageHero from './components/PageHero';
import LocationsSection from './components/LocationSection';
import WelcomeSection from './components/WelcomeSection';
import LocationMap from './components/LocationMap';
import PhotoGallery from './components/PhotoGallery';
import CTABanner from './components/CTABanner';

import { loadScript } from './helpers';

import 'normalize.css';
import './App.css';
import OrderModal from './components/OrderModal';
import SideMenu from './components/SideMenu';
import MenuHandle from './components/MenuHandle';
import SiteLoader from './components/SiteLoader';
import Menus from './components/Menus';

class App extends React.Component {

  static contextType = GlobalContext;

  componentDidMount() {
    loadScript('https://www.fbgcdn.com/embedder/js/ewm2.js');
  }

  render() {
    return (
      <GlobalProvider>
        <div className="App">
          <SiteLoader />
          <SideMenu />
          <MenuHandle />
          <DefaultLayout
            masthead={
              <Masthead />
            }
            content={
              <React.Fragment>
                <PageHero vidUrl="2epa2-pw4tg" />
                <WelcomeSection />
                <LocationsSection />
                <Menus />
                <LocationMap />
                <PhotoGallery />
                <CTABanner />
                <OrderModal />
              </React.Fragment>
            }
            footer={
              <Footer />
            }/>
        </div>
      </GlobalProvider>
    );
  }
}

export default App;
