import React, { useContext } from 'react';

import { GlobalContext } from '../context/GlobalContext';

const CTABanner = () => {
  const context = useContext(GlobalContext);

  return (
    <section id="cta-banner" className="Nvl-before-bg">
      <div className="Nvl-container">
        <div className="cta-order Nvl-txt-center">
          <button onClick={() => context.openOrderModal()} className="cta-order-btn">
            <img src="/assets/images/chops.gif" alt="Chopsticks and bowl icon"/>
            ORDER ONLINE
          </button>
        </div>
      </div>
    </section>
  )
}

export default CTABanner
