import React from 'react';
import { menus, colors } from '../helpers';

const Menus = () => {
  return (
    <section id="Menus" className="Nvl-section">
      <div className="Nvl-container">
        <div className="akk-menus-list">
          <h3 className="Nvl-section-title sm-txt-c">OUR <span>MENUS</span></h3>
          <div className="akk-menus-list-inner">
            {menus.map((m,i) => (
              <p key={i}>
                <a href={`/assets/media/menus/${m.pdf}`} target="_blank" rel="noopener noreferrer">
                  <i style={{color: colors.red}} className="akk-icon-book-open"></i>
                  {m.label}
                </a>
              </p>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Menus;
