import React, { useContext, useEffect, useState } from 'react';

import { GlobalContext } from '../context/GlobalContext';

const MenuHandle = () => {
  const context = useContext(GlobalContext);

  const [handleClass, setHandleClass] = useState('menu-handle');

  useEffect(() => {
    if(context.menuOpen) {
      setHandleClass('menu-handle closed');
    }
    else {
      setHandleClass('menu-handle');
    }

  }, [context.menuOpen])

  return (
    <div className={handleClass} onClick={() => context.toggleMenu()}>
      <div className="menu-handle-icon">
          <div className="menu-handle-line menu-handle-line--top"></div>
          <div className="menu-handle-line menu-handle-line--middle"></div>
          <div className="menu-handle-line menu-handle-line--bottom"></div>
      </div>
    </div>
  )
}

export default MenuHandle;
