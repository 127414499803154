import React, { useContext } from 'react';

import { GlobalContext } from '../context/GlobalContext';

const Masthead = () => {
  const context = useContext(GlobalContext);

  return (
    <div className="Nvl-masthead Nvl-container">
      <a href="#Home" style={{display: 'block'}}>
        <img className="masthead-logo" src="/assets/images/logo-w.svg" alt="Akakiko logo" />
      </a>

      <nav className="Nvl-main-nav">
        <ul className="Nvl-nav-list">
          <li className="Nvl-nav-list-item Nvl-list-i1">
            <a href="#Home" className="Nvl-nav-list-link">HOME</a>
          </li>
          <li className="Nvl-nav-list-item Nvl-list-i2">
            <a href="#Locations" className="Nvl-nav-list-link">LOCATIONS</a>
          </li>
          <li className="Nvl-nav-list-item Nvl-list-i2">
            <a href="#Menus" className="Nvl-nav-list-link">MENUS</a>
          </li>
          <li className="Nvl-nav-list-item Nvl-list-i3">
            <a href="#Gallery" className="Nvl-nav-list-link">PHOTOS</a>
          </li>
          <li className="Nvl-nav-list-item Nvl-list-i4">
            <a href="#Contact" className="Nvl-nav-list-link">CONTACT</a>
          </li>
        </ul>
      </nav>

      <a href="#Order" onClick={() => context.openOrderModal()} className="Nvl-nav-list-link Nvl-order-online">
        <img src="/assets/images/chops.gif" alt="Chopsticks and bowl icon" />
        <span>ORDER ONLINE</span>
      </a>
    </div>
  )
}

export default Masthead;
