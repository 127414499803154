import React, { useContext, useEffect } from 'react';

import { GlobalContext } from '../../context/GlobalContext';

const DefaultLayout = props => {
    const context = useContext(GlobalContext);

    useEffect(() => {
        setTimeout(() => {
            context.addPost();
        }, 3000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    return (
      <div className="nvl-layout nvl-layout-default">
          <header className="nvl-layout-masthead-wrapper">
            {props.masthead}
          </header>
          <div className="nvl-layout-content-wrapper">
            {props.content}
          </div>
          <div className="nvl-layout-footer-wrapper">
            {props.footer}
          </div>
      </div>
    );
}

export default DefaultLayout;
